import { baseEnvironmentProd } from 'src/environments/baseEnvironment.prod';

export const environment = {
  ...baseEnvironmentProd,
  organizationPrefix: 'DKC',
  organizationUUID: 'bd92cbb6-f913-4e46-b29f-5266463e3a6a',
  activeLanguage: 'en-US',
  customProductExportUrl: 'https://dkc-integration.datalean-nodejs.catalean.com/product/export',
  customProductDatasheetUrl: 'https://dkc-integration.datalean-nodejs.catalean.com/product/',
  customProductImportUrl: 'https://dkc-integration.datalean-nodejs.catalean.com/product/',
  exportECPUrl: 'https://dkc-export-ecp.datalean-nodejs.catalean.com/export/',
  exportCatalogue: 'https://dkc-integration.datalean-nodejs.catalean.com/product/xml',
  // exportCatalogue: 'http://localhost:3023/product/xml',
  tableStructureUUID: '',
  multipleAdminCommunity: true,
  requiredField: {},
  systemToken: '',
  htmlFields: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ header: 1 }, { header: 2 }],
    ['blockquote'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }, 'link'],
    [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
    ['clean'],
  ],

  basicConfiguration: {
    htmlEditorMaxLength: {
      active: false,
      where: [
        {
          name: 'product',
          value: 270,
        },
      ],
    },
  },
  googleAPIKey: '',
  manageTemplates: false,
  manageUserFilter: false,

  productEditorTabIcons: ['r', 'z', 'M', 'x', 'm'],
  pwaThemeColor: 'rgba(0, 72, 205, 1.0)',
};
